import axios from 'axios';
import weevaluateagent from './weevaluateagent';

const API_BASE_URL = '/v1'; // Replace with your API base URL

const jobService = {
 
  // Get all jobs
  getAllJobs: async (token) => {
    try {
      const response = await weevaluateagent.requestsA.get(`${API_BASE_URL}/jobs`)
      return response;
    } catch (error) {
      throw error
    }
  },

  // Get a job by ID
  getJobById: async (jobId) => {
    try {
      const response = await weevaluateagent.requestsA.get(`${API_BASE_URL}/jobs/${jobId}`);
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  
};

export default jobService;
