import React, { Component } from "react";
import superagent from '../services/weevaluateagent';
import candidateService from "../services/candidateService";

class EmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      otp: '',
      message: '',
      isOtpSent: false,
      isLoading: false,
      isOtpValid: true,
      isEmailValid: true,
    };

    this.sendOtp = this.sendOtp.bind(this);
    this.verifyOtp = this.verifyOtp.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleOtpChange = this.handleOtpChange.bind(this);
  }

  async sendOtp() {
    if (!this.validateEmail(this.state.email)) {
      this.setState({ isEmailValid: false, message: 'Invalid email address' });
      return;
    }

    this.setState({ isLoading: true, isEmailValid: true, message: '' });

    try {
      const { authorizationKey } = this.props;
      const token = authorizationKey;
      superagent.setToken(token);
      localStorage.setItem('userEmail', this.state.email);
      const response = await candidateService.sendOTP(this.state.email);
      this.setState({ message: response.message, isOtpSent: true, isLoading: false });
    } catch (error) {
      this.setState({ message: 'Error sending OTP', isLoading: false });
    }
  }

  async verifyOtp() {
    if (!this.validateOtp(this.state.otp)) {
      this.setState({ isOtpValid: false, message: 'Invalid OTP' });
      return;
    }

    this.setState({ isLoading: true, isOtpValid: true, message: '' });

    try {
      const { authorizationKey } = this.props;
      const token = authorizationKey;
      superagent.setToken(token);
      const response = await candidateService.verifyOTP(this.state.email, this.state.otp);
      if (response) {
        this.setState({ message: response.message, isLoading: false });
        if (response.responseStatus === 200) {
          // Store candidate and token in localStorage
         
          localStorage.setItem('verificationToken', response.candidate.verificationToken);
          localStorage.setItem('candidateData', JSON.stringify(response.candidate));

          this.props.setCandidate(response.candidate);
          this.props.navigateTo('candidate', this.props.job);
        }
        if (response.responseStatus === 204) {
          this.props.navigateTo('candidate', this.props.job);
        }
      }
    } catch (error) {
      this.setState({ message: 'Error verifying OTP', isLoading: false });
    }
  }

  validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  validateOtp(otp) {
    const re = /^[0-9]{6}$/; // Assuming OTP is a 6-digit number
    return re.test(otp);
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleOtpChange(e) {
    this.setState({ otp: e.target.value });
  }

  render() {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="max-w-lg mx-auto bg-white rounded p-8 shadow-md w-full animate__animated animate__fadeIn">
          <h2 className="emailverify text-center text-2xl font-bold mb-6">Email Verification</h2>
          <div>
            <input
              type="email"
              value={this.state.email}
              onChange={this.handleEmailChange}
              placeholder="Enter your email"
              className={`shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full ${!this.state.isEmailValid && 'border-red-500'}`}
            />
            {!this.state.isEmailValid && <p className="text-red-500 text-xs italic">Please enter a valid email.</p>}
            <button 
              onClick={this.sendOtp} 
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-2 rounded focus:outline-none focus:shadow-outline w-full disabled:opacity-50"
              disabled={this.state.isLoading}
            >
              {this.state.isLoading ? 'Sending...' : 'Send OTP'}
            </button>
            <br />
            <br />
            {this.state.isOtpSent && (
              <>
                <input
                  type="text"
                  value={this.state.otp}
                  onChange={this.handleOtpChange}
                  placeholder="Enter OTP"
                  className={`shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full ${!this.state.isOtpValid && 'border-red-500'}`}
                />
                {!this.state.isOtpValid && <p className="text-red-500 text-xs italic">Please enter a valid OTP.</p>}
                <button 
                  onClick={this.verifyOtp} 
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mt-2 rounded focus:outline-none focus:shadow-outline w-full disabled:opacity-50"
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? 'Verifying...' : 'Verify OTP'}
                </button>
              </>
            )}
            <p className={`text-center mt-4 ${this.state.isOtpSent && 'text-green-500'}`}>{this.state.message}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default EmailVerification;
