import React, { Component, createRef } from 'react';
import 'tailwindcss/tailwind.css';

class ApplicationProcessTracker extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = createRef();
  }

  componentDidMount() {
    this.drawTracker();
  }

  componentDidUpdate() {
    this.drawTracker();
  }

  drawTracker() {
    const { steps, stepsPerRow = 3,navigateTo,job } = this.props;
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');

    const stepRadius = 30;
    const stepSpacing = 110*(1.1+stepsPerRow/10);
    const verticalSpacing = 100*(1+stepsPerRow/10);
    const lineWidth = 4;

    const colors = {
      complete: '#10b981',
      active: '#3b82f6',
      incomplete: '#d1d5db',
    };

    const numRows = Math.ceil(steps.length / stepsPerRow);
    const canvasHeight = numRows * verticalSpacing + 200;

    canvas.width = (stepsPerRow+(.3+stepsPerRow/10)) * stepSpacing;
    canvas.height = canvasHeight;

    function drawStep(x, y, status, index) {
      setTimeout(() => {
        ctx.beginPath();
        ctx.arc(x, y, stepRadius, 0, Math.PI * 2, false);
        ctx.fillStyle = colors[status];
        ctx.fill();
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 2;
        ctx.stroke();

        ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
        ctx.shadowBlur = 8;
        ctx.shadowOffsetX = 4;
        ctx.shadowOffsetY = 4;

        ctx.font = '16px Arial';
        ctx.fillStyle = '#333';
        ctx.textAlign = 'center';

        ctx.fillText(steps[index].label, x, y + stepRadius + 20);
        ctx.fillText(steps[index].date, x, y + stepRadius + 40);

        ctx.shadowBlur = 0;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
      }, index * 320);
    }

    function drawLine(x1, y1, x2, y2, status, index) {
      setTimeout(() => {
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.strokeStyle = status === 'complete' || status === 'active' ? colors.active : colors.incomplete;
        ctx.lineWidth = lineWidth;
        ctx.stroke();
      }, index * 200);
    }

    function drawCurvedLine(x1, y1, x2, y2, direction, status, index) {
      setTimeout(() => {
        ctx.beginPath();
        ctx.moveTo(x1, y1 - stepRadius);
        ctx.lineTo(x1 + direction * (stepSpacing / 2), y1 - stepRadius);
        ctx.lineTo(x1 + direction * (stepSpacing / 2), y2 + stepRadius);
        ctx.lineTo(x2, y2 + stepRadius);
        ctx.strokeStyle = status === 'complete' || status === 'active' ? colors.active : colors.incomplete;
        ctx.lineWidth = lineWidth;
        ctx.stroke();
      }, index * 150);
    }

    const startX = 90*(1+stepsPerRow/10);
    const startY = 100;

    for (let i = 0; i < steps.length; i++) {
      const isSecondRow = Math.floor(i / stepsPerRow) % 2 === 1;
      const stepX = isSecondRow ? startX + (stepsPerRow - 1 - (i % stepsPerRow)) * stepSpacing : startX + (i % stepsPerRow) * stepSpacing;
      const stepY = startY + Math.floor(i / stepsPerRow) * verticalSpacing;

      if (i > 0) {
        const prevStepX = Math.floor((i - 1) / stepsPerRow) % 2 === 1
          ? startX + (stepsPerRow - 1 - ((i - 1) % stepsPerRow)) * stepSpacing
          : startX + ((i - 1) % stepsPerRow) * stepSpacing;
        const prevStepY = startY + Math.floor((i - 1) / stepsPerRow) * verticalSpacing;

        if (i % stepsPerRow === 0) {
          const direction = i % (2 * stepsPerRow) === 0 ? -1 : 1;
          drawCurvedLine(prevStepX, prevStepY + stepRadius, stepX, stepY - stepRadius, direction, steps[i - 1].status, i);
        } else {
          drawLine(prevStepX + stepRadius, prevStepY, stepX - stepRadius, stepY, steps[i - 1].status, i);
        }
      }

      drawStep(stepX, stepY, steps[i].status, i);
    }
  }

  render() {
    return (
      <div className="tracker-container flex flex-col justify-center items-center p-20 bg-gray-100">
  <canvas ref={this.canvasRef} className="bg-white rounded-lg shadow-lg" />
  <button onClick={() => this.props.navigateTo('appliedjobs', this.props.job)} className="mt-4 bg-blue-500 text-white px-3 py-2 rounded-md">Back to Applied Jobs</button>
</div>

    );
  }
}

export default ApplicationProcessTracker;
