import React from 'react';
import Careers from "./careers";
import './tailwind.min.css';

// Define keys in an array with corresponding hostnames
const keys = [
  { hostname: "dev.chorapalli.com", key: 'REVWLG9ueWVnQG1haWx0by5wbHVzLDgyMDA2' },
  { hostname: "localhost", key: 'b25wLG9ueWVncHJvQG1haWx0by5wbHVzLDE5MDcwNg==' },
  // Add more hostnames and keys as needed
];

function App() {
  // Function to determine the authorization key based on hostname
  const getAuthorizationKey = () => {
    const currentHostname = window.location.hostname;
    const keyInfo = keys.find(k => k.hostname === currentHostname);
    return keyInfo ? keyInfo.key : "default_key_if_needed"; // Default key if hostname not matched
  };

  return (
    <div className="App">
      <Careers authorizationKey={getAuthorizationKey()} modal="true" />
    </div>
  );
}

export default App;
