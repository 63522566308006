import React, { Component } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

class InlineButtonControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  handleEditClick = (e) => {
    e.preventDefault();
    this.setState({ isEditing: true });
    this.props.editClick();
  };

  handleSaveClick = (e) => {
    e.preventDefault();
    this.setState({ isEditing: false });
    this.props.saveClick();
  };

  render() {
    const { isEditing } = this.state;

    return (
      <div className="flex items-center space-x-4">
        <FaPencilAlt
          onClick={this.handleEditClick}
          className={`flex items-center justify-center mb-2 text-blue-500 w-6 h-6 cursor-pointer ${isEditing ? 'hidden' : ''}`}
        />
        <FontAwesomeIcon
          icon={faSave}
          onClick={this.handleSaveClick}
          className={`flex items-center justify-center text-green-500 w-6 h-6 cursor-pointer ${isEditing ? '' : 'hidden'}`}
        />
      </div>
    );
  }
}

export default InlineButtonControl;
