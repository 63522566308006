import React from 'react';
import { Menu } from '@headlessui/react';
import { DotsVerticalIcon, EyeIcon, ClipboardListIcon } from '@heroicons/react/solid';

const AppliedJobsTable = ({ appliedJobs, candidate,navigateTo }) => {
  const viewDetails = (job) => {
    console.log('Viewing details for job:', job);
  };

  const withdrawApplication = (job) => {
    console.log('Withdrawing application for job:', job);
  };

  const editApplication = (job) => {
    console.log('Editing application for job:', job);
  };

  const trackStatus = (job) => {
    console.log('Tracking status for job:', job);
    navigateTo('tracker')
  };

  const contactRecruiter = (job) => {
    console.log('Contacting recruiter for job:', job);
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">{candidate.fullName}'s Applied Jobs</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {appliedJobs.map((job, index) => (
          <div key={index} className="bg-white shadow-md rounded-lg p-4 border border-gray-200">
            <h3 className="text-lg font-semibold mb-2">{job.title}</h3>
            <p className="text-sm text-gray-600 mb-2"><span className="font-semibold">Experience:</span> {job.experience}</p>
            <p className="text-sm text-gray-600 mb-4"><span className="font-semibold">Status:</span> {job.status}</p>
            <div className="flex space-x-2 mb-2">
              <button className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 flex items-center" onClick={() => viewDetails(job)}>
                <EyeIcon className="h-5 w-5 mr-1" /> View Details
              </button>
              <button className="bg-green-600 text-white px-3 py-1 rounded hover:bg-green-700 flex items-center" onClick={() => trackStatus(job)}>
                <ClipboardListIcon className="h-5 w-5 mr-1" /> Track Status
              </button>
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                          } group flex items-center px-4 py-2 text-sm`}
                          onClick={() => editApplication(job)}
                        >
                          Edit
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                          } group flex items-center px-4 py-2 text-sm`}
                          onClick={() => withdrawApplication(job)}
                        >
                          Withdraw
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                          } group flex items-center px-4 py-2 text-sm`}
                          onClick={() => contactRecruiter(job)}
                        >
                          Contact Recruiter
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Menu>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppliedJobsTable;
