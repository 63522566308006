import React, { Component } from 'react';
import Accordion from '../../components/Accordion';
import WorkExperience from '../../components/WorkExperience';
import Education from '../../components/Education';
import Skills from '../../components/Skills';
import MandatoryData from '../../components/MandatoryFields';
import candidateService from '../../services/candidateService';
import superagent from "../../services/weevaluateagent";
import UploadResumeWithDropzone from '../UploadResumeWithDropzone';

class CandidateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      isExisted: false,
      isEditing: true,
      isMainDetailsEdit: false,
      isWorkExpDetailsEdit: false,
      isEduDetailsEdit: false,
      isSkillDetailsEdit: false,
      isWorkExpAccordionOpen: false,
      isEduAccordionOpen: false,
      isSkillAccordionOpen: false,
      candidate: null,
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        resume: '',
        overAllExp: '',
        linkedin: '',
        github: '',
        workExperience: [{ jobTitle: '', company: '', location: '', startDate: '', endDate: '' }],
        education: [{ degree: '', institution: '', graduationDate: '' }],
        skills: [{ skill: '', availability: '' }],
        status: [{ Status: '', Date: '' }],
        jobId: '',
      }
    };
    this.toggleWorkExpAccordion = this.toggleWorkExpAccordion.bind(this);
    this.toggleEduAccordion = this.toggleEduAccordion.bind(this);
    this.toggleSkillAccordion = this.toggleSkillAccordion.bind(this);
    this.addWorkExperience = this.addWorkExperience.bind(this);
    this.addEducation = this.addEducation.bind(this);
    this.addSkills = this.addSkills.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteEducation = this.deleteEducation.bind(this);
    this.deleteWorkExperience = this.deleteWorkExperience.bind(this);
    this.deleteSkills = this.deleteSkills.bind(this);
    this.mainDetailsEdit = this.mainDetailsEdit.bind(this);
    this.mainDetailsSave = this.mainDetailsSave.bind(this);
    this.workExpDetailsEdit = this.workExpDetailsEdit.bind(this);
    this.workExpDetailsSave = this.workExpDetailsSave.bind(this);
    this.eduDetailsEdit = this.eduDetailsEdit.bind(this);
    this.eduDetailsSave = this.eduDetailsSave.bind(this);
    this.skillDetailsEdit = this.skillDetailsEdit.bind(this);
    this.skillDetailsSave = this.skillDetailsSave.bind(this);
    this.applyJob = this.applyJob.bind(this); // Bind the applyJob method
  }

  componentDidMount() {
    if (this.props.existingCandidate) {
      this.setState({
        isExisted: true,
        formData: this.props.existingCandidate
      });
    }
  }

  toggleWorkExpAccordion() {
    this.setState(prevState => ({
      isWorkExpAccordionOpen: !prevState.isWorkExpAccordionOpen
    }));
  }

  toggleEduAccordion() {
    this.setState(prevState => ({
      isEduAccordionOpen: !prevState.isEduAccordionOpen
    }));
  }

  toggleSkillAccordion() {
    this.setState(prevState => ({
      isSkillAccordionOpen: !prevState.isSkillAccordionOpen
    }));
  }

  addWorkExperience() {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        workExperience: [...prevState.formData.workExperience, { jobTitle: '', company: '', location: '', startDate: '', endDate: '' }]
      }
    }));
  }

  addEducation() {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        education: [...prevState.formData.education, { degree: '', institution: '', graduationDate: '' }]
      }
    }));
  }

  addSkills() {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        skills: [...prevState.formData.skills, { skill: '', availability: '' }]
      }
    }));
  }

  handleChange(e, index) {
    const { name, value } = e.target;
    const { formData } = this.state;

    if (name.includes('_')) {
      const [fieldName, experienceIndex] = name.split('_');

      const updatedFormData = { ...formData };

      const isFieldInWorkExperience = formData.workExperience.some(workObj => fieldName in workObj);
      if (isFieldInWorkExperience) {
        updatedFormData.workExperience[index][fieldName] = value;
      }

      const isFieldInEducation = formData.education.some(eduObj => fieldName in eduObj);
      if (isFieldInEducation) {
        updatedFormData.education[index][fieldName] = value;
      }

      const isFieldInSkills = formData.skills.some(sklObj => fieldName in sklObj);
      if (isFieldInSkills) {
        updatedFormData.skills[index][fieldName] = value;
      }

      this.setState({ formData: updatedFormData });
    } else {
      if (name === 'resume') {
        const file = document.getElementById('resume').files[0];
        this.setState(prevState => ({
          formData: {
            ...prevState.formData,
            resume: file
          }
        }));
      } else {
        this.setState(prevState => ({
          formData: {
            ...prevState.formData,
            [name]: value
          }
        }));
      }
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { formData } = this.state;
    const { job, navigateTo, getAccessTokenSilently } = this.props;

    try {
      formData.jobId = [job._id];
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await candidateService.createCandidate({ formData });
      this.setState({
        formData: response,
        id: response._id,
        isExisted: true,
        isEditing: false
      });
      alert("Applied to the job successfully");
    } catch (error) {
      console.error('Error submitting candidate form data:', error);
    }
  }


  async applyJob() {
    console.log("I am in applyJob")
    console.log(this.props)

    try {
      const { authorizationKey } = this.props;
      const token = authorizationKey;
      superagent.setToken(token);
      const response = await candidateService.applyJob(this.state.formData.email, this.props.job);
      if (response) {
     //   this.setState({ message: response.message, isLoading: false });
        if (response.responseStatus === 200) {
          // Store candidate and token in localStorage
         
          localStorage.setItem('candidateData', JSON.stringify(response.candidate));
          this.props.setCandidate(response.candidate);
          this.props.navigateTo('appliedjobs', this.props.job);
        }
        if (response.responseStatus === 204) {
          this.props.navigateTo('appliedjobs', this.props.job);
        }
      }
    } catch (error) {
      console.log(error)
    //  this.setState({ message: 'Error Applying Job', isLoading: false });
    }
  }

  deleteEducation(index) {
    this.setState(prevState => {
      const updatedEducation = [...prevState.formData.education];
      updatedEducation.splice(index, 1);
      return {
        formData: {
          ...prevState.formData,
          education: updatedEducation
        }
      };
    });
  }

  deleteWorkExperience(index) {
    this.setState(prevState => {
      const updatedWorkExperience = [...prevState.formData.workExperience];
      updatedWorkExperience.splice(index, 1);
      return {
        formData: {
          ...prevState.formData,
          workExperience: updatedWorkExperience
        }
      };
    });
  }

  deleteSkills(index) {
    this.setState(prevState => {
      const updatedSkills = [...prevState.formData.skills];
      updatedSkills.splice(index, 1);
      return {
        formData: {
          ...prevState.formData,
          skills: updatedSkills
        }
      };
    });
  }

  mainDetailsEdit() {
    this.setState({ isMainDetailsEdit: true });
  }

  mainDetailsSave() {
    this.setState({ isMainDetailsEdit: false });
  }

  workExpDetailsEdit() {
    this.setState({
      isWorkExpDetailsEdit: true,
      isEditing: true
    });
  }

  workExpDetailsSave() {
    this.setState({
      isWorkExpDetailsEdit: false,
      isEditing: false
    });
  }

  eduDetailsEdit() {
    this.setState({
      isEduDetailsEdit: true,
      isEditing: true
    });
  }

  eduDetailsSave() {
    this.setState({
      isEduDetailsEdit: false,
      isEditing: false
    });
  }

  skillDetailsEdit() {
    this.setState({
      isSkillDetailsEdit: true,
      isEditing: true
    });
  }

  skillDetailsSave() {
    this.setState({
      isSkillDetailsEdit: false,
      isEditing: false
    });
  }

  setCandidate = (candidate) => {
    this.setState({
      candidate,
      isExisted: true,
      formData: {
        firstName: candidate.firstName || '',
        lastName: candidate.lastName || '',
        email: candidate.email || '',
        phone: candidate.phone || '',
        resume: candidate.resume || '',
        overAllExp: candidate.overAllExp || '',
        linkedin: candidate.linkedin || '',
        github: candidate.github || '',
        workExperience: candidate.workExperience || [{ jobTitle: '', company: '', location: '', startDate: '', endDate: '' }],
        education: candidate.education || [{ degree: '', institution: '', graduationDate: '' }],
        skills: candidate.skills || [{ skill: '', availability: '' }],
        status: candidate.status || [{ Status: '', Date: '' }],
        jobId: candidate.jobId || '',
      },
      id: candidate._id,
      isEditing: false,
    });
  };

  render() {
    const {
      isExisted, isEditing, isMainDetailsEdit, isWorkExpDetailsEdit,
      isEduDetailsEdit, isSkillDetailsEdit, isWorkExpAccordionOpen,
      isEduAccordionOpen, isSkillAccordionOpen, formData
    } = this.state;
    const { job, navigateTo, authorizationKey } = this.props;

    return (
      <div className="max-w-4xl mx-auto bg-white rounded p-8 shadow-md w-full">
        <div className="flex justify-end">
        </div>

        <div>
          {!isExisted && <UploadResumeWithDropzone job={job} navigateTo={navigateTo} setCandidate={this.setCandidate} authorizationKey={authorizationKey} />}
        </div>

        {isExisted &&
          <div>
            <MandatoryData
              formData={formData}
              isExisted={isExisted}
              isMainDetailsEdit={isMainDetailsEdit}
              handleChange={this.handleChange}
              mainDetailsSave={this.mainDetailsSave}
              mainDetailsEdit={this.mainDetailsEdit}
            />

            <Accordion title="Work Experience" isAccordionOpen={isWorkExpAccordionOpen} toggleAccordion={this.toggleWorkExpAccordion}>
              <WorkExperience
                workExperience={formData.workExperience}
                isExisted={isExisted}
                isEditing={isEditing}
                isWorkExpDetailsEdit={isWorkExpDetailsEdit}
                handleChange={this.handleChange}
                deleteWorkExperience={this.deleteWorkExperience}
                addWorkExperience={this.addWorkExperience}
                workExpDetailsSave={this.workExpDetailsSave}
                workExpDetailsEdit={this.workExpDetailsEdit}
              />
            </Accordion>

            <Accordion title="Education" isAccordionOpen={isEduAccordionOpen} toggleAccordion={this.toggleEduAccordion}>
              <Education
                education={formData.education}
                isExisted={isExisted}
                isEditing={isEditing}
                isEduDetailsEdit={isEduDetailsEdit}
                handleChange={this.handleChange}
                deleteEducation={this.deleteEducation}
                addEducation={this.addEducation}
                eduDetailsSave={this.eduDetailsSave}
                eduDetailsEdit={this.eduDetailsEdit}
              />
            </Accordion>

            <Accordion title="Skills" isAccordionOpen={isSkillAccordionOpen} toggleAccordion={this.toggleSkillAccordion}>
              <Skills
                skills={formData.skills}
                isExisted={isExisted}
                isEditing={isEditing}
                isSkillDetailsEdit={isSkillDetailsEdit}
                handleChange={this.handleChange}
                deleteSkills={this.deleteSkills}
                addSkills={this.addSkills}
                skillDetailsSave={this.skillDetailsSave}
                skillDetailsEdit={this.skillDetailsEdit}
              />
            </Accordion>

            <div className="flex justify-end">
              <button
                onClick={() => (navigateTo('home'))}
                type="button"
                className="m-2bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Cancel
              </button>
          <button
                onClick={this.applyJob}
                type="button"
                className="m-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Apply
              </button>
              <button
                onClick={this.handleSubmit}
                className={`m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-2 rounded focus:outline-none focus:shadow-outline ${isExisted ? 'hidden' : ''}`}
              >
                Save Candidate 
              </button>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default CandidateForm;
