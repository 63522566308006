import React, { Component } from "react";

class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExisted: false,
      isEditing: false,
      isMainDetailsEdit: false,
      formData: {
        _id: "",
        title: "",
        experience: "",
        noOfPositions: "",
        skills: [],
        location: "",
        description: "",
        qualifications: [],
        salary: 0,
        minSalary: "",
        maxSalary: "",
        rolesAndResponsibilities: [],
        benefits: [],
        applicationDeadline: this.getDefaultDate(),
        hiringManager: "",
        company: {
          name: "",
          website: "",
        },
        contactEmail: "",
        jobpdf: "", // file upload field
        equalOpportunityStatement: "",
        createdBy: "",
        updateDate: "",
      },
      skill: [],
    };
  }

  componentDidMount() {
    const { job } = this.props;
    if (job) {
      console.log("Job Id from props", job._id);
      this.setState({
        isExisted: true,
        formData: job,
        skill: job.skills,
      });
    }
  }

  getDefaultDate = () => {
    const defaultDate = new Date("2024-05-19"); // Default date set to May 19, 2024
    return defaultDate.toISOString().split("T")[0]; // Format date as 'YYYY-MM-DD'
  };

  render() {
    const { navigateTo, job } = this.props;
    const { formData } = this.state;

    return (
      <div className="container mx-auto p-4 bg-gray-100 min-h-screen">
        <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-6">
          <div className="mb-6">
            <h1 className="text-2xl font-bold text-gray-800">{formData.title}</h1>
            <p className="text-gray-600 mt-2">{formData.description}</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <h2 className="text-lg font-semibold text-gray-700 mb-2">Job Details</h2>
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                <p><span className="font-semibold">Experience:</span> {formData.experience}</p>
                <p><span className="font-semibold">Location:</span> {formData.location}</p>
                <p><span className="font-semibold">Positions:</span> {formData.noOfPositions}</p>
                <p><span className="font-semibold">Salary Range:</span> {formData.minSalary} - {formData.maxSalary}</p>
                <p><span className="font-semibold">Application Deadline:</span> {formData.applicationDeadline}</p>
                <p><span className="font-semibold">Hiring Manager:</span> {formData.hiringManager}</p>
                <p><span className="font-semibold">Contact Email:</span> {formData.contactEmail}</p>
              </div>
            </div>
            <div>
              <h2 className="text-lg font-semibold text-gray-700 mb-2">Skills</h2>
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                <div>
                  <p>{formData.skills.join(", ")}</p>
                </div>
                
              </div>
            </div>
          </div>
          <div className="mb-6">
                  <h2 className="text-lg font-semibold text-gray-700 mb-2">Requirements:</h2>
                  <ul className="list-disc list-inside">
                    {formData.qualifications.map((qualification, index) => (
                      <li key={index}>{qualification}</li>
                    ))}
                  </ul>
                </div>
          <div className="mb-6">
            <h2 className="text-lg font-semibold text-gray-700 mb-2">Roles & Responsibilities</h2>
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
              <ul className="list-disc list-inside">
                {formData.rolesAndResponsibilities.map((role, index) => (
                  <li key={index}>{role}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="mb-6">
            <h2 className="text-lg font-semibold text-gray-700 mb-2">Benefits</h2>
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
              <p>{formData.benefits.join(", ")}</p>
            </div>
          </div>

          <div className="flex justify-center space-x-4">
            <button
              onClick={() => navigateTo('home', job)}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              Cancel
            </button>
            <button
              onClick={() => navigateTo('apply', job)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default JobDetails;
