import weevaluateagent from './weevaluateagent';

const API_BASE_URL = '/v1'; // Replace with your API base URL

const candidateService = {
  // Upload resume for a candidate by ID
  uploadResume: async (file, jobId, email) => {
    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("jobId", jobId);
      formData.append("resume", file);

      console.log(formData);
      const response = await weevaluateagent.requestsA.post(
        `${API_BASE_URL}/candidates/upload-resume`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response;
    } catch (error) {
      throw error;
    }
  },

  // Create a new candidate
  createCandidate: async (candidateData) => {
    try {
      console.log("in candidateService", candidateData)
      const formData = new FormData();

      for (const key in candidateData.formData) {
        console.log(key)
        if (['workExperience', 'education', 'skills', 'status'].includes(key)) {
          console.log('at 35')
          formData.append(key, JSON.stringify(candidateData.formData[key]));
        } else {
          console.log('at 38')
          formData.append(key, candidateData.formData[key]);
        }
      }
      console.log('formData', formData);
      const response = await weevaluateagent.requestsA.post(`${API_BASE_URL}/candidates/`, formData);
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  sendOTP: async (email) => {
    console.log("email :", email)
    try {
      const response = await weevaluateagent.requestsA.post(
        `${API_BASE_URL}/candidates/send-otp`, { email });
      return response;
    } catch (error) {
      throw error;
    }
  },

  verifyOTP: async (email, otp) => {
    try {
      const response = await weevaluateagent.requestsA.post(
        `${API_BASE_URL}/candidates/verify-otp`, { email, otp });
      console.log(response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  applyJob: async (email, jobId) => {
    try {
      const response = await weevaluateagent.requestsA.post(
        `${API_BASE_URL}/candidates/apply-job`, { email, jobId });
      console.log(response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  // Fetch initial enquiries based on email and jobId
  getInitialEnquiries: async (email, jobId) => {
    console.log("Hello",email, jobId)
    try {
      const response = await weevaluateagent.requestsA.get(
        `${API_BASE_URL}/candidates/initial-enquiries?email=${email}&&jobId=${jobId}`
      );
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  // Submit initial enquiries based on email and jobId
  submitInitialEnquiries: async (email, jobId, questions) => {
    try {
      const response = await weevaluateagent.requestsA.post(
        `${API_BASE_URL}/candidates/initial-enquiries`,
        { email, jobId, questions }
      );
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },
};

export default candidateService;
