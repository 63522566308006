import React, { Component } from 'react';
import candidateService from '../services/candidateService'; // Adjust the import path as needed
import superagent from "../services/weevaluateagent";

class InitialEnquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuestionIndex: 0,
      questions: [],
      answers: {},
      isButtonDisabled: true,
      stream: null,
      speech: new window.SpeechSynthesisUtterance(),
      voices: [],
      spokenQuestions: new Set(),
      email: localStorage.getItem('userEmail') || 'sairam001@mailto.plus',
      jobId: '667bb3779a26ae72e411438e',
      webcamError: false,
      showSuccessMessage: false
    };
  }

  componentDidMount() {
    const { job, existingCandidate } = this.props;
    if (job) {
      console.log("Job Id from props", job._id);
      this.setState({
        jobId: job._id,
        email: existingCandidate?.email || 'sairam001@mailto.plus',
      });
    }
    this.startWebcam();
    this.webcamCheckInterval = setInterval(this.checkWebcamStatus, 1000); // Check webcam status every second
    if ('speechSynthesis' in window) {
      window.speechSynthesis.onvoiceschanged = this.setIndianVoice;
      this.setIndianVoice();
    }
  }

  componentWillUnmount() {
    clearInterval(this.webcamCheckInterval); // Clear interval on unmount
    this.stopWebcam();
  }

  fetchQuestions = async () => {
    const { email, jobId } = this.state;
    const { authorizationKey } = this.props;
    try {
      const token = authorizationKey;
      superagent.setToken(token);
      const data = await candidateService.getInitialEnquiries(email, jobId);
      console.log('Initial Enquiries', data);
      this.setState({ questions: data.initialEnquiries }, () => {
        this.renderQuestion(0);
      });
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  }

  setIndianVoice = () => {
    const voices = window.speechSynthesis.getVoices();
    const indianVoice = voices.find(voice => voice.lang.includes('en-IN'));
    this.setState({ speech: { ...this.state.speech, voice: indianVoice || voices[0] } });
  }

  startWebcam = () => {
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(mediaStream => {
          this.setState({ stream: mediaStream, webcamError: false });
          this.video.srcObject = mediaStream;
          if(this.state.currentQuestionIndex===0)
          this.fetchQuestions();
          mediaStream.getTracks().forEach(track => {
            track.onended = () => {
              console.error('Webcam access stopped.');
              this.setState({ webcamError: true });
            };
          });
        })
        .catch(error => {
          console.error('Error accessing webcam:', error);
          this.setState({ webcamError: true });
        });
    } else {
      this.setState({ webcamError: true });
    }
  }

  checkWebcamStatus = () => {
    if (this.state.stream && !this.state.stream.active) {
      console.error('Webcam has been stopped.');
      this.setState({ webcamError: true });
    }
  }

  stopWebcam = () => {
    if (this.state.stream) {
      const tracks = this.state.stream.getTracks();
      tracks.forEach(track => track.stop());
    }
  }

  renderQuestion = (index) => {
    const { questions, speech, spokenQuestions } = this.state;
    const question = questions[index];

    if (question && !spokenQuestions.has(index)) {
      const updatedSpeech = new window.SpeechSynthesisUtterance();
      updatedSpeech.text = question.question;
      updatedSpeech.voice = speech.voice;
      window.speechSynthesis.speak(updatedSpeech);
      this.setState(prevState => ({
        spokenQuestions: new Set(prevState.spokenQuestions).add(index)
      }));
    }

    this.setState({
      currentQuestionIndex: index,
      isButtonDisabled: index !== questions.length - 1
    });
  }

  handleInputChange = (event) => {
    const { currentQuestionIndex, questions, answers } = this.state;
    const updatedQuestions = questions.map((item, index) => {
      if (index === currentQuestionIndex) {
        return { ...item, answer: event.target.value }; // Adds the answer to the specific question
      }
      return item;
    });

    const question = questions[currentQuestionIndex].question;
    const value = event.target.value;

    this.setState({
      questions: updatedQuestions,
      answers: { ...answers, [question]: value },
      isButtonDisabled: false
    }, () => {
      sessionStorage.setItem('questions', JSON.stringify(updatedQuestions));
      sessionStorage.setItem('answers', JSON.stringify({...answers, [question]: value}));
    });
  }

  handleNextClick = () => {
    const { currentQuestionIndex, questions } = this.state;
    if (currentQuestionIndex < questions.length - 1) {
      this.renderQuestion(currentQuestionIndex + 1);
    } else {
      this.handleEndClick();
    }
  }

  handleSkipClick = () => {
    const { currentQuestionIndex, questions } = this.state;
    if (currentQuestionIndex < questions.length - 1) {
      this.renderQuestion(currentQuestionIndex + 1);
    }
  }

  handleEndClick = async () => {
    const { email, jobId, questions } = this.state;
    const { authorizationKey } = this.props;
   
    try {
      const token = authorizationKey;
      superagent.setToken(token);  
      await candidateService.submitInitialEnquiries(email, jobId, questions);
      this.showSuccessMessage();
    } catch (error) {
      console.error('Error submitting answers:', error);
    }
  }

  showSuccessMessage = () => {
    this.stopWebcam();
    this.setState({ showSuccessMessage: true });
  }

  goBackToJobs = () => {
    alert('Going back to applied jobs...');
  }

  render() {
    const { questions, currentQuestionIndex, isButtonDisabled, showSuccessMessage, webcamError } = this.state;
    const question = questions[currentQuestionIndex];

    return (
        <div className="bg-gray-100 flex justify-center items-center min-h-screen">
        <div className="w-full max-w-6xl bg-white rounded-lg shadow-md p-6 relative flex flex-col md:flex-row">
      
          {/* Video Container */}
          <div className="w-full md:w-1/2 flex justify-center items-center mb-6 md:mb-0 md:pr-8">  {/* Increased right padding on laptop screens */}
            <video ref={el => this.video = el} autoPlay className="rounded-lg shadow-md max-w-full h-auto"></video>
          </div>
      
          {/* Content Container */}
          <div className="w-full md:w-1/2 md:pl-8">  {/* Increased left padding on laptop screens */}
            {webcamError ? (
              <div className="text-center p-4">
                <h2 className="text-xl font-semibold mb-4">Webcam Access Needed</h2>
                <p>Please enable your webcam to proceed with the initial enquiries.</p>
                <button onClick={this.startWebcam} className="px-4 py-2 bg-blue-500 text-white rounded">Retry Webcam</button>
              </div>
            ) : !showSuccessMessage ? (
              <div id="question-container" className="px-4">  {/* Slightly more padding inside the question container */}
                {question && (
                  <div className="question-card">
                    <h2 className="text-xl font-semibold mb-4">{question.question}</h2>
                    {question.options.map((option, index) => (
                      <label key={index} className="block mb-4 text-base">  {/* Increased margin and base text size for better readability */}
                        <input
                          type={question.type}
                          name={question.question}
                          value={option}
                          className="mr-3"
                          onChange={this.handleInputChange}
                          checked={this.state.answers[question.question] === option}
                        />
                        {option}
                      </label>
                    ))}
                    <div className="flex flex-col md:flex-row justify-between mt-6">
                      {currentQuestionIndex < questions.length - 1 && (
                        <button
                          className="px-5 py-3 mb-2 md:mb-0 rounded text-white bg-yellow-500"
                          onClick={this.handleSkipClick}
                        >
                          Skip
                        </button>
                      )}
                      <button
                        className={`px-5 py-3 rounded text-white ${currentQuestionIndex < questions.length - 1 ? '' : 'w-full'} ${isButtonDisabled ? 'bg-gray-500' : (currentQuestionIndex < questions.length - 1 ? 'bg-blue-500' : 'bg-green-500')}`}
                        disabled={isButtonDisabled}
                        onClick={this.handleNextClick}
                      >
                        {currentQuestionIndex < questions.length - 1 ? 'Next' : 'End'}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div id="success-message" className="text-center">
                <h2 className="text-2xl font-semibold mb-4">Success!</h2>
                <p className="mb-4">Your responses have been submitted successfully.</p>
                <button onClick={this.goBackToJobs} className="px-5 py-3 bg-blue-500 text-white rounded">Go Back to Applied Jobs</button>
              </div>
            )}
          </div>
        </div>
      </div>
      
      
      
      
    );
  }
}

export default InitialEnquiry;
