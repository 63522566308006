import React, { Component } from 'react';
import Modal from 'react-modal';
import JobListings from '../ats/components/JobListings';
import JobDetails from '../ats/components/Job/JobDetails';
import CandidateForm from '../ats/components/Candidate/CandidateForm';
import EmailVerification from '../ats/components/EmailVerification';
import AppliedJobs from '../ats/components/AppliedJobs';
import '../index.css';
import ApplicationProcessTracker from '../ats/components/ApplicationProcessTracker';
import InitialEnquiry from '../ats/components/InitialEnquiry';
const steps = [
  { label: "Received", tooltip: "Application successfully submitted", status: "complete", date: "01-July" },
  { label: "Resume Viewed", tooltip: "Recruiter or hiring manager has viewed the resume", status: "complete", date: "02-07" },
  { label: "Analyzed", tooltip: "AI-powered screening tools have analyzed the resume and cover letter", status: "complete", date: "03-07" },
  { label: "Initial Questioning", tooltip: "Applicant has answered initial screening questions", status: "complete", date: "04-07" },
  { label: "HR Enquiry", tooltip: "HR representative has reviewed the application and contacted the applicant", status: "active", date: "05-07" },
  { label: "Interview Schedule", tooltip: "Applicant has been scheduled for an interview", status: "incomplete", date: "06-07" },
  { label: "Interview Conducted", tooltip: "Interview has taken place", status: "incomplete", date: "07-07" },
  { label: "Assessment Test", tooltip: "Applicant has completed a skills assessment or psychological test", status: "incomplete", date: "08-07" },
  { label: "Reference Check", tooltip: "Employer has contacted the applicant's references", status: "incomplete", date: "09-07" },
  { label: "Final Review", tooltip: "Hiring manager has reviewed the application and interview feedback", status: "incomplete", date: "10-07" },
  { label: "Offer Extended", tooltip: "Job offer has been made to the applicant", status: "incomplete", date: "11-07" },
  { label: "Offer Accepted", tooltip: "Applicant has accepted the job offer", status: "incomplete", date: "12-07" },
  { label: "Onboarding", tooltip: "Applicant is going through the onboarding process before starting the job", status: "incomplete", date: "13-07" },
];

class Careers extends Component {
  constructor(props) {
    super(props);
    const savedPage = localStorage.getItem('currentPage') || 'home';
    const savedJob = JSON.parse(localStorage.getItem('currentJob')) || null;
    const savedToken = localStorage.getItem('verificationToken') || null;
    const savedCandidate = JSON.parse(localStorage.getItem('candidateData')) || null;

    this.state = {
      page: savedPage,
      job: savedJob,
      candidate: savedCandidate,
      verificationToken: savedToken,
      jobs: [],
      isOpen: false,
    };
  }

  navigateTo = (pageName, job) => {
    this.setState({ page: pageName, job });
    localStorage.setItem('currentPage', pageName);
    if (job) {
      localStorage.setItem('currentJob', JSON.stringify(job));
    } else {
      localStorage.removeItem('currentJob');
    }
  };

  setVerificationToken = (token) => {
    this.setState({ verificationToken: token });
    localStorage.setItem('verificationToken', token);
  };

  setCandidate = (candidate) => {
    this.setState({ candidate });
    localStorage.setItem('candidateData', JSON.stringify(candidate));
  };

  setJobs = (jobs) => {
    this.setState({ jobs });
  };

  closeModal = () => {
    this.setState({ isOpen: false, page: 'home' });
  };

  render() {
    const { page, job, candidate, verificationToken, jobs, isOpen } = this.state;
    const { authorizationKey, modal } = this.props;

    let content;
    switch (page) {
      case 'home':
        content = <JobListings navigateTo={this.navigateTo} authorizationKey={authorizationKey} setJobs={this.setJobs} />;
        break;
      case 'apply':
        if (verificationToken) {
          content = <CandidateForm job={job} navigateTo={this.navigateTo} existingCandidate={candidate} authorizationKey={authorizationKey} />;
        } else {
          content = <EmailVerification job={job} navigateTo={this.navigateTo} setCandidate={this.setCandidate} setVerificationToken={this.setVerificationToken} authorizationKey={authorizationKey} />;
        }
        break;
      case 'candidate':
        content = job && <CandidateForm job={job} navigateTo={this.navigateTo} existingCandidate={candidate} setCandidate={this.setCandidate} authorizationKey={authorizationKey} />;
        break;
      case 'jobdetails':
        content = job && <JobDetails job={job} navigateTo={this.navigateTo} authorizationKey={authorizationKey} />;
        break;
      case 'appliedjobs':
        content = <AppliedJobs candidate={candidate} jobsData={jobs} navigateTo={this.navigateTo} />;
        break;
      case 'tracker':
        content =  <ApplicationProcessTracker steps={steps} stepsPerRow={2} navigateTo={this.navigateTo}/>;
        break;
      case 'initialEnquiry':
        content =  <InitialEnquiry job={job} existingCandidate={candidate} authorizationKey={authorizationKey} navigateTo={this.navigateTo}/>;
        break;
     
       
      default:
        content = <JobListings navigateTo={this.navigateTo} authorizationKey={authorizationKey} setJobs={this.setJobs} />;
    }

    const screenHeight = window.innerHeight - 100; // Adjusted height calculation

    const modalContent = (
      <Modal isOpen={modal && page !== 'home'} onRequestClose={this.closeModal} className="modal h-screen-3/4">
        <div className="flex justify-between items-center px-4 py-2 bg-gray-200">
          <h2 className="text-lg font-semibold">Career Page</h2>
          <button onClick={this.closeModal} className="text-gray-600 hover:text-gray-900">
            X
          </button>
        </div>
        <div className="bg-white p-4" style={{ height: `${screenHeight}px`, overflow: 'auto' }}>
          {content}
        </div>
      </Modal>
    );

    return (
      <div id="weevaluate-sdk">
        {!modal && (
          <main>
            {content}
          </main>
        )}

        {modal && (page === 'home' ? (
          <main>
            {content}
          </main>
        ) : (
          <main>
            {modalContent}
          </main>
        ))}
      </div>
    );
  }
}

export default Careers;
