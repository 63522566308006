import React, { Component } from "react";
import superagent from "../../services/weevaluateagent";
import jobService from "../../services/jobService";
import "./styles.css";

class JobListings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
      isLoading: true,
      isError: false,
      searchQuery: '',
      filteredData: [],
      currentPage: 1,
      jobsPerPage: 3,
    };
  }

  async componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      const { authorizationKey, setJobs } = this.props;
      const token = authorizationKey;
      superagent.setToken(token);
      const response = await jobService.getAllJobs();
      console.log("In JobsList", response);
      setJobs(response);
      this.setState({ formData: response, filteredData: response, isLoading: false });
    } catch (error) {
      console.log(error.response);
      this.setState({ isError: true, isLoading: false });
      console.error(
        "Error fetching jobs:",
        error.response ? error.response.body?.error : "Error"
      );
    }
  };

  handleSearchChange = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    const filteredData = this.state.formData.filter((data) =>
      (data.title?.toLowerCase().includes(searchQuery) || '') ||
      (data.location?.toLowerCase().includes(searchQuery) || '') ||
      (data.experience?.toLowerCase().includes(searchQuery) || '') ||
      (data.skills.some(skill => skill.toLowerCase().includes(searchQuery)) || '')
    );
    this.setState({ searchQuery, filteredData, currentPage: 1 });
  };

  paginate = (pageNumber) => this.setState({ currentPage: pageNumber });

  render() {
    const { navigateTo } = this.props;
    const { filteredData, isError, isLoading, searchQuery, currentPage, jobsPerPage, formData } = this.state;

    // Get current jobs
    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;
    const currentJobs = filteredData.slice(indexOfFirstJob, indexOfLastJob);

    // Page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData.length / jobsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="container mx-auto p-4">
        {isError && (
          <div className="flex justify-center items-center h-screen">
            <div className="bg-red-500 text-white p-4 rounded-lg">Invalid Key</div>
          </div>
        )}
        {isLoading && (
          <div className="flex justify-center items-center h-screen">
            <div className="loader"></div>
          </div>
        )}
        {!isError && !isLoading && formData.length === 0 && (
          <div className="flex flex-col justify-center items-center h-screen">
            <div className="bg-gray-200 text-gray-700 p-4 rounded-lg text-center">
              <h2 className="text-2xl font-bold mb-2">No Job Openings</h2>
              <p className="text-lg">We currently do not have any job openings. Please check back later!</p>
            </div>
          </div>
        )}
        {!isError && !isLoading && formData.length > 0 && (
          <main>
           {formData.length >3 && (<div className="search-bar flex justify-center mb-4">
              <input
                type="text"
                placeholder="Search by job title, location, experience, or skills"
                value={searchQuery}
                onChange={this.handleSearchChange}
                className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full max-w-lg"
              />
            </div>)}
            <div className="job-listing grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {currentJobs.map((data, index) => (
                <div className="job bg-white rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow duration-300" key={index}>
                  <div className="job-info">
                    <h2 className="text-xl font-bold mb-2">{data.title}</h2>
                    <p className="text-gray-700 mb-2">{data.description.split(" ").slice(0, 20).join(" ")}...</p>
                    <div className="tags flex space-x-2 mt-2">
                      <span className="bg-yellow-200 text-yellow-800 px-2 py-1 rounded">{data.location}</span>
                      <span className="bg-blue-200 text-blue-800 px-2 py-1 rounded">{data.experience}</span>
                    </div>
                    <h3 className="text-lg font-semibold mt-4">Top Skills</h3>
                    <ul className="list-disc list-inside">
                      {data.skills.slice(0, 3).map((skill, skillIndex) => (
                        <li key={skillIndex}>{skill}</li>
                      ))}
                    </ul>
                    <div className="job-salary mt-4 flex justify-between items-center">
                      {data.minSalary && data.maxSalary && (
                        <span className="text-lg font-semibold">Salary: {data.minSalary} - {data.maxSalary}</span>
                      )}
                      <span className="bg-green-200 text-green-800 px-2 py-1 rounded">Open Positions: {data.noOfPositions}</span>
                    </div>
                    <div className="mt-4 flex space-x-2">
                      <button
                        onClick={() => navigateTo("apply", data)}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-32"
                      >
                        Apply Now
                      </button>
                      <button
                        onClick={() => navigateTo("jobdetails", data)}
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded w-32"
                      >
                        Job Details
                      </button>
                      <button
                        onClick={() => navigateTo("initialEnquiry", data)}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4  rounded w-32"
                      >
                       Enquiry
                      </button>
                      
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="pagination flex justify-center mt-4">
              {pageNumbers.map(number => (
                <button key={number} onClick={() => this.paginate(number)} className={`px-4 py-2 mx-1 rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}>
                  {number}
                </button>
              ))}
            </div>
          </main>
        )}
      </div>
    );
  }
}

export default JobListings;
