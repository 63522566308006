import React, { Component } from 'react';
import DropzoneWrapper from './DropzoneWrapper'; // Import the wrapper
import superagent from '../services/weevaluateagent';
import candidateService from '../services/candidateService';
import AILoading from './AILoading';


class UploadResumeWithDropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileSelected: false,
      error: null,
    };

    this.onDrop = this.onDrop.bind(this);
  }

  async onDrop(acceptedFiles) {
    const { job, navigateTo, setCandidate, authorizationKey } = this.props;

    if (acceptedFiles.length === 0) {
      this.setState({ error: 'Resume is missing. Please drop a resume file.' });
    
      return;
    }

    const file = acceptedFiles[0]; // Assume only one file is dropped
    const acceptedFileTypes = ['pdf'];
    const ext = file?.name?.split('.').pop().toLowerCase();

    if (!acceptedFileTypes.includes(ext)) {
      this.setState({ error: 'Please drop a resume in PDF format.' });
   
      return;
    }

    try {
      this.setState({ fileSelected: true, error: null });
      const token = authorizationKey;
      superagent.setToken(token);
      const userEmail = localStorage.getItem('userEmail');
      const response = await candidateService.uploadResume(file, job._id, userEmail);
      console.log('response of resume', response);
      setCandidate(response);

     
      this.setState({ fileSelected: false });
      navigateTo('candidate', job);
    } catch (error) {
      console.error(error);
      let errorMessage = 'Error uploading resume';
      if (error.status === 409 || error.status === 400) {
        errorMessage = error.response.body.message;
      }
      this.setState({ fileSelected: false, error: errorMessage });
 
    }
  }

  render() {
    const { fileSelected, error } = this.state;

    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
          {!fileSelected ? (
            <DropzoneWrapper onDrop={this.onDrop}>
              {({ isDragActive, openFileDialog }) => (
                <div
                  onClick={openFileDialog}
                  className={`dropzone border-2 border-dashed rounded-lg p-10 ${
                    isDragActive ? 'border-green-500 bg-green-100' : 'border-gray-300 bg-gray-100'
                  }`}
                >
                  <p className="font-bold text-center text-gray-700">
                    Drag your resume here or click to select a PDF file.
                  </p>
                  {error && <p className="text-red-500 text-center mt-2">{error}</p>}
                </div>
              )}
            </DropzoneWrapper>
          ) : (
            <AILoading text="WeEvaluate AI is analyzing candidate's resume" type="model" />
          )}
        </div>
      </div>
    );
  }
}

export default UploadResumeWithDropzone;
