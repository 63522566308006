// AppliedJobs.js
import React from 'react';
import AppliedJobsTable from './AppliedJobsTable'; // Assuming this is the table component we created earlier

const AppliedJobs = ({ candidate, jobsData, navigateTo }) => {
    console.log('jobsData',jobsData)
  if (!candidate || !candidate.jobId || candidate.jobId.length === 0) {
    return <div>No applied jobs found.</div>;
  }

  const appliedJobs = jobsData.filter(job => candidate.jobId.includes(job._id));

  return (
    <div className="p-4">
      
      {appliedJobs.length>0 && <AppliedJobsTable appliedJobs={appliedJobs}  candidate={candidate} navigateTo={navigateTo}/>}
      <button onClick={() => navigateTo('home')} className="mt-4 bg-blue-500 text-white px-3 py-2 rounded-md">Back to Job Listings</button>
    </div>
  );
};

export default AppliedJobs;
