import React from 'react';
import InlineButtonControl from './InlineButtonControl';

//import UploadResumeWithDropzone from './UploadResumeWithDropzone';


const MandatoryData = ({ formData, isExisted, isMainDetailsEdit, handleChange, mainDetailsSave, mainDetailsEdit }) => {
  return (
    <div>
    {<div className='flex justify-end'>
        {isExisted && <InlineButtonControl saveClick={mainDetailsSave} editClick={mainDetailsEdit} />}
        </div> }
        <div className='flex justify-start w-full'>
       {/*<UploadResumeWithDropzone setSelectedCandidate={formData}/>*/} 
               </div>
        <div className="grid grid-cols-2 gap-4">
        <div className="mb-4 ">
          <label htmlFor="firstName" className="block text-gray-700 font-bold mb-2 leftside_text">First Name</label>
          {!isExisted || isMainDetailsEdit ? (
              <input 
                type="text" 
                id="firstName" 
                name="firstName" 
                value={formData.firstName} 
                onChange={handleChange} 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                placeholder="Your First Name" 
                required 
              />
            ) : (
              <div className='data-value'>{formData.firstName}</div>
            )}
        </div>

        <div className="mb-4">
          <label htmlFor="lastName" className="block text-gray-700 font-bold mb-2 leftside_text">Last Name</label>
          {!isExisted || isMainDetailsEdit ? (
              <input 
                type="text" 
                id="lastName" 
                name="lastName" 
                value={formData.lastName} 
                onChange={handleChange} 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                placeholder="Your Last Name" 
                required 
              />
            ) : (
              <div className='data-value'>{formData.lastName}</div>
            )}
          </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 font-bold mb-2 leftside_text">Email</label>
          {!isExisted || isMainDetailsEdit ? (
              <input 
                type="email" 
                id="email" 
                name="email" 
                value={formData.email} 
                onChange={handleChange} 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                placeholder="Your Email" 
                required 
              />
            ) : (
              <div className='data-value'>{formData.email}</div>
            )}

        </div>

        <div className="mb-4">
          <label htmlFor="phone" className="block text-gray-700 font-bold mb-2 leftside_text">Phone</label>
          {!isExisted || isMainDetailsEdit ? (
            <input 
              type="tel" 
              id="phone" 
              name="phone" 
              value={formData.phone} 
              onChange={handleChange} 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              placeholder="Your Phone" 
              required 
            />
          ) : (
            <div className='data-value'>{formData.phone}</div>
          )}

        </div>

        <div className="mb-4">
          <label htmlFor="linkedin" className="block text-gray-700 font-bold mb-2 leftside_text">LinkedIn</label>
          {!isExisted || isMainDetailsEdit ? (
            <input 
              type="url" 
              id="linkedin" 
              name="linkedin" 
              value={formData.linkedin} 
              onChange={handleChange} 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              placeholder="Your LinkedIn Profile" 
            />
          ) : (
            <div className='data-value'>{formData.linkedin}</div>
          )}

        </div>

        <div className="mb-4">
          <label htmlFor="github" className="block text-gray-700 font-bold mb-2 leftside_text">GitHub</label>
          {!isExisted || isMainDetailsEdit ? (
          <input 
            type="url" 
            id="github" 
            name="github" 
            value={formData.github} 
            onChange={handleChange} 
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
            placeholder="Your GitHub Profile" 
          />
        ) : (
          <div className='data-value'>{formData.github}</div>
        )}

                </div>
      

      <div className="mb-4">
       
        {!isExisted || isMainDetailsEdit ? (
          <>
          <label htmlFor="resume" className="block text-gray-700 font-bold mb-2 leftside_text">Resume</label>
          <input 
            type="file" 
            id="resume" 
            name="resume" 
            //accept=".pdf" 
            onChange={handleChange} 
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
          />
          </>
        ) : (
          <div className='data-value'>{/*formData.resume*/}</div> 
        )}

      </div>
      <div className="mb-4">
          <label htmlFor="overAllExp" className="block text-gray-700 font-bold mb-2 leftside_text">Over All Experience</label>
          {!isExisted || isMainDetailsEdit ? (
              <input 
                type="text" 
                id="overAllExp" 
                name="overAllExp" 
                value={formData.overAllExp} 
                onChange={handleChange} 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                placeholder="Over All Experience" 
                required 
              />
            ) : (
              <div className='data-value'>{formData.overAllExp} </div>
            )}
        </div>

        </div>
    </div>
  );
};

export default MandatoryData;
