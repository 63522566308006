const apiUrls = {
  "dev.partner.weevaluate.ai": "https://o7t10fn984.execute-api.us-east-1.amazonaws.com/dev/webpack",
  "partner.weevaluate.ai": "https://r66uzg98ml.execute-api.us-east-1.amazonaws.com/uat/webpack",
  "beta.weevaluate.ai": "https://8eel1c30ki.execute-api.us-east-1.amazonaws.com/dev/webpack",
  "localhost": "http://localhost:3024/webpack",
  "192.168.31.191": "http://192.168.31.191:3024/webpack",
  "dev": "https://o7t10fn984.execute-api.us-east-1.amazonaws.com/dev/webpack",
};

function getApiRoot(hostname) {
  // Return the URL if the exact hostname matches any key in the apiUrls object
  if (apiUrls[hostname]) {
    return apiUrls[hostname];
  }

  // Extract the subdomain from the hostname
  const subdomain = hostname.split('.')[0];

  // Check if the subdomain starts with 'dev', 'test', 'stg', or 'uat'
  const subdomainPrefixes = ['dev', 'test', 'stg', 'uat'];
  const isTestEnvironment = subdomainPrefixes.some(prefix => subdomain.startsWith(prefix));

  // Return the URL for the 'dev' key if it's a test environment
  if (isTestEnvironment) {
    return apiUrls['dev'];
  }

  // Default URL if no conditions are met
  return "https://r66uzg98ml.execute-api.us-east-1.amazonaws.com/uat/webpack";
}

// Export the apiUrls object and the getApiRoot function
export { apiUrls, getApiRoot };
