import React, { Component } from 'react';

class DropzoneWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDragActive: false,
      droppedFiles: []
    };

    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  onDragEnter(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isDragActive: true });
  }

  onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isDragActive: true });
  }

  onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isDragActive: false });
  }

  onDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    const files = Array.from(event.dataTransfer.files);
    this.setState({ isDragActive: false, droppedFiles: files });
    if (this.props.onDrop) {
      this.props.onDrop(files);
    }
  }

  render() {
    const { isDragActive } = this.state;
    const { children } = this.props;

    const dropzoneStyles = {
      borderRadius: '4px',
      padding: '20px',
      textAlign: 'center',
      cursor: 'pointer',
      background: isDragActive ? '#e0e0e0' : '#ffffff',
    };

    return (
      <div
        style={dropzoneStyles}
        onDragEnter={this.onDragEnter}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={() => this.fileInput.click()}
      >
        <input
          type="file"
          style={{ display: 'none' }}
          ref={(input) => (this.fileInput = input)}
          onChange={(e) => {
            const files = Array.from(e.target.files);
            this.setState({ droppedFiles: files });
            if (this.props.onDrop) {
              this.props.onDrop(files);
            }
          }}
        />
        {children({ isDragActive })}
      </div>
    );
  }
}

export default DropzoneWrapper;
